import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { HeroStyled, HeroCornersStyled } from './HeroStyled';

export default function Hero({ theme, image, titleFirst, titleSecond }) {
  const [scrolled, setScrolled] = useState(false);
  const scrollRef = useRef();

  scrollRef.current = scrolled;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 300;
      if (scrollRef.current !== show) {
        setScrolled(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeroStyled className="hero" theme={theme}>
      <h2 className={scrolled ? 'hidden' : ''}>
        <span>{titleFirst}</span>
        <br />
        {titleSecond}
      </h2>
      <Img
        fluid={image}
        objectFit="cover"
        fadeIn
        objectPosition="50% 50%"
        alt=""
      />
      <HeroCornersStyled className="corners" theme={theme}>
        <div className="top-right" />
        <div className="bottom-right">opening winter 2023 (hopefully)</div>
        <div className="bottom-left" />
      </HeroCornersStyled>
    </HeroStyled>
  );
}

Hero.propTypes = {
  theme: PropTypes.string,
  titleFirst: PropTypes.string,
  titleSecond: PropTypes.string,
  image: PropTypes.object,
};

Hero.defaultProps = {
  theme: 'neutral',
  titleFirst: '',
  titleSecond: '',
  image: {},
};
