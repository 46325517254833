import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const HeroStyled = styled('div')({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    position: 'relative',
    display: 'flex',
    zIndex: 0,
    paddingLeft: 50,
    alignItems: 'flex-end',
    height: '80vh',
    paddingBottom: 100,

    [media('desktop')]: {
      paddingBottom: 0,
      alignItems: 'center',
      paddingLeft: 200,
      height: '90vh',
    },

    '.gatsby-image-wrapper': {
      width: '100%',
      height: '100%',
      position: 'absolute !important',
      top: 0,
      left: 0,
      zIndex: 0
    },

    h2: {
      // color: 'white',
      position: 'fixed',
      zIndex: 1,
      transition: 'opacity 300ms ease-in-out',

      '&.hidden': {
        opacity: 0
      }
    },
},
props =>
  props.theme === 'yellow' && {
    h2: {
      color: 'white',
    },
  },
props =>
  props.theme === 'dark' && {
    h2: {
      color: 'white',
    },
  },
props =>
  props.theme === 'neutral' && {
    [media('desktop')]: {
      height: '60vh',
    },
  }
)


export const HeroCornersStyled = styled('div')({

    div: {
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: 0,
      width: 10,
      height: 10,
      zIndex: 2,
    },

    '.top-right': {
      top: 50,
      right: 50,
      borderRightWidth: 2,
      borderTopWidth: 2,
    },

    '.bottom-right': {
      bottom: 50,
      right: 50,
      /* border-right-width: 2,
      border-bottom-width: 2, */
      textAlign: 'right',
      width: 'auto',
      fontSize: 10,
    },

    '.bottom-left': {
      bottom: 50,
      left: 50,
      borderLeftWidth: 2,
      borderBottomWidth: 2,
    }

},
props =>
  props.theme === 'yellow' && {
    div: {
      borderColor: '#ffff91',
    },

    '.bottom-right': {
      color: '#ffff91',
    },
  },
props =>
  props.theme === 'dark' && {
    div: {
      borderColor: '#ff0056',
    },

    '.bottom-right': {
      color: '#ff0056',
    },
  }
)
