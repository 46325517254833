import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const SectionStyled = styled('div')({
    zIndex: 1,
    position: 'relative',
    backgroundColor: '#f7f7f7'
},
props =>
  props.theme === 'yellow' && {
    backgroundColor: '#ffffe7',

    'a': {
      color: '#0b5394',
    },
  },
props =>
  props.bg && {
    backgroundColor: props.bg
  },
props =>
  props.color && {
    color: props.color
  }
);

export const WrapperStyled = styled('div')({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexFlow: 'row wrap',
},
props =>
  props.wrapReverse && {
    flexFlow: 'row wrap-reverse',
  }
);
