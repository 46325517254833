import React from 'react';
import PropTypes from 'prop-types';

import { SectionStyled, WrapperStyled } from './SectionStyled.js';

export default function Section({ children, theme, bg, color, wrapReverse}) {
  return (
    <SectionStyled theme={theme} bg={bg} color={color}>
      <WrapperStyled wrapReverse={wrapReverse}>
        {children}
      </WrapperStyled>
    </SectionStyled>
  );
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  wrapReverse: PropTypes.bool,
};

Section.defaultProps = {
  bg: '',
  color: '',
  wrapReverse: false
};
